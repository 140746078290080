import React from "react";
import { useContext } from "react";
import { LocaleContext } from "../layout/layout";
import {
  imprint,
  imprintContent,
  imprintHeading,
  imprintDisclaimer,
  section,
  sectionBig,
} from "./imprint.module.scss";
import { Link } from "gatsby";

const ImprintComponent = () => {
  const risLink =
    "https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10007517";
  const ecEuLink = "https://ec.europa.eu/consumers/odr";
  const { locale } = useContext(LocaleContext);
  return (
    <>
      {locale === "de" ? (
        <div className={imprint}>
          <div className={imprintContent}>
            <h2 className={imprintHeading}>Impressum</h2>

            <h4 className={imprintDisclaimer}>
              Für den Inhalt dieser Website ist die Powerfusion GmbH & Co KG
              verantwortlich.
            </h4>

            <p>Powerfusion GmbH & Co KG</p>
            <p>Triesterstraße 10/4/424</p>
            <p>2351 Wiener Neudorf</p>

            <p className={section}>E-Mail: office@powerfusion.at</p>

            <p className={section}>
              Geschäftsführer: Herr Mag. D'Alonzo Vittorio
            </p>
            <p>
              Unternehmensgegenstand: Entwicklung und Errichtung
              Energieversorgungssystemen
            </p>

            <p className={section}>Firmenbuchnummer: FN 526019 h</p>
            <p className={section}>
              Die Powerfusion GmbH & Co KG ist Mitglied der Wirtschaftskammer
              Österreich. Die Gewerbeordnung 1994 ist anwendbar und kann{" "}
              <Link target="_blank" to={risLink}>
                hier
              </Link>{" "}
              eingesehen werden.
            </p>

            <p className={sectionBig}>
              RECHTLICHER HINWEIS / STREITSCHLICHTUNG
            </p>
            <p className={section}>
              Gemäß Art. 14 Abs 1 Verordnung (EU) 524/2013 (Verordnung über
              Online-Streitbeilegung in Verbraucherangelegenheiten) weisen wir
              auf die, von der EU-Kommission bereitgestellte, online Plattform
              für außergerichtliche Streitschlichtung hin. Verbraucher haben
              dort die Möglichkeit, Streitigkeiten im Zusammenhang mit einem
              online abgeschlossenen Kauf- oder Dienstleistungsvertrag zunächst
              ohne Einschaltung eines Gerichts zu klären. Die
              Streitbeilegungsplattform kann unter{" "}
              <Link target="_blank" to={ecEuLink}>
                https://ec.europa.eu/consumers/odr
              </Link>{" "}
              aufgerufen werden.
            </p>
          </div>
        </div>
      ) : (
        <div className={imprint}>
          <div className={imprintContent}>
            <h2 className={imprintHeading}>Imprint</h2>

            <h4 className={imprintDisclaimer}>
              Powerfusion GmbH & Co KG is responsible for the content of this
              website.
            </h4>

            <p>Powerfusion GmbH & Co KG</p>
            <p>Triesterstraße 10/4/424</p>
            <p>2351 Wiener Neudorf</p>

            <p className={section}>E-mail: office@powerfusion.at</p>

            <p className={section}>CEO: Mag. D'Alonzo Vittorio</p>
            <p>
              Object of the company: Development and construction of
              sustainable, individually tailored energy supply systems and
              distribution of products for the construction and control of these
              energy supply systems.
            </p>

            <p className={section}>Company registration number: FN 526019 h</p>
            <p className={section}>
              Powerfusion GmbH is a member of the Austrian Federal Economic
              Chamber. The Trade Regulation Act 1994 is applicable and can be
              viewed{" "}
              <Link target="_blank" to={risLink}>
                here.
              </Link>{" "}
            </p>

            <p className={sectionBig}>LEGAL NOTICE / DISPUTE RESOLUTION</p>
            <p className={section}>
              In accordance with Art. 14 (1) of Regulation (EU) 524/2013
              (Regulation on Online Dispute Resolution in Consumer Matters), we
              refer to the online platform for out-of-court dispute resolution
              provided by the EU Commission. Consumers have the opportunity to
              resolve disputes in connection with a purchase or service contract
              concluded online without having to go to court. The dispute
              resolution platform can be accessed at {" "}
              <Link target="_blank" to={ecEuLink}>
                https://ec.europa.eu/consumers/odr
              </Link>{" "}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ImprintComponent;
