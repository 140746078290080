import ImprintComponent from "../components/imprint/imprint";
import React from "react";
import Seo from "../components/seo/seo";

const Imprint = () => {
  return (
    <>
      <Seo title="Impressum" />
      <ImprintComponent />
    </>
  );
};

export default Imprint;
