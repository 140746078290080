// extracted by mini-css-extract-plugin
export var dBlock = "imprint-module--d-block--101d2";
export var dFlex = "imprint-module--d-flex--62b6a";
export var dInline = "imprint-module--d-inline--7ca91";
export var dLgBlock = "imprint-module--d-lg-block--09f54";
export var dLgFlex = "imprint-module--d-lg-flex--a7dca";
export var dLgInline = "imprint-module--d-lg-inline--d8b6c";
export var dLgNone = "imprint-module--d-lg-none--109ff";
export var dMdBlock = "imprint-module--d-md-block--1d37f";
export var dMdFlex = "imprint-module--d-md-flex--26303";
export var dMdNone = "imprint-module--d-md-none--1c20c";
export var dNone = "imprint-module--d-none--f17c4";
export var imprint = "imprint-module--imprint--6cf38";
export var imprintContent = "imprint-module--imprintContent--f4006";
export var imprintDisclaimer = "imprint-module--imprintDisclaimer--07f7e";
export var imprintHeading = "imprint-module--imprintHeading--25f93";
export var imprintTitle = "imprint-module--imprintTitle--fb51f";
export var section = "imprint-module--section--8de18";
export var sectionBig = "imprint-module--sectionBig--f6998";